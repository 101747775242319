import React, { useEffect } from 'react';

import {
  QAlert,
  QBox,
  QFlex,
  QFormControl,
  QHeading,
  QIcon,
  QMultiSelect,
  QStack,
  QText,
  QTag,
  QDivider,
  QLink,
} from '@qualio/ui-components';
import { FormikProps } from 'formik';
import { useCompanyUsersQuery, useConfigsQuery, usePoliciesQuery, usePolicyLinksQuery } from 'hooks';
import { getRequiredAudits, maybePluralize } from 'lib';
import { Document, File } from 'providers';
import { routes } from 'utils';
import { ViewFileCard } from 'v2components';
import { AddSupplierFormikPayload, ConfigItem } from 'v2types';

import WarningMessage from '../WarningMessage/WarningMessage';

type ApprovalFormProps = {
  formik: FormikProps<AddSupplierFormikPayload>;
  handleStepChange: (stepIndex: number) => void;
};

const ADDITIONAL_DOCUMENTS = 'Additional documents';
const ApprovalSimpleItem: React.FC<{ title: string; description?: string }> = ({ title, description }) => (
  <span>
    <QText fontSize="sm" weight="semibold">
      {title}
    </QText>
    <QText color="gray.500" fontSize="sm">
      {description ? description : '--'}
    </QText>
  </span>
);

export const ApprovalForm: React.FC<ApprovalFormProps> = ({ formik, handleStepChange }) => {
  const { values, errors, touched, setFieldValue, status } = formik;
  const { usersOptions, isCompanyUsersLoading } = useCompanyUsersQuery();

  const isFieldInvalid = (field: keyof AddSupplierFormikPayload) => {
    return !!touched && !!touched[field] && !!errors[field];
  };

  const formDocuments = values.documents;
  const { policyDocuments } = usePolicyLinksQuery(formDocuments, values.supplierType?.value, values.riskType?.id);
  const { isConfigsLoading, auditTypeOptions } = useConfigsQuery();
  const { policyLinks } = usePoliciesQuery(isConfigsLoading);
  const requiredAudits = getRequiredAudits(
    policyLinks,
    auditTypeOptions as unknown as ConfigItem[],
    values.supplierType as unknown as ConfigItem,
    values.riskType as unknown as ConfigItem,
  );

  useEffect(() => {
    if (formDocuments.length === 0) {
      setFieldValue('documents', policyDocuments);
    }
  }, [policyDocuments, setFieldValue, formDocuments.length]);

  const isEmpty = (item?: string | number) => (item ? item : '--');

  return (
    <QBox maxWidth="640px" mb={2}>
      <QHeading mb={2}>Approval</QHeading>
      <QFlex align="center" pb={2}>
        <QIcon iconName="Info" color="gray.500" />
        <QText fontSize="sm" color="gray.500" pl={2}>
          Review supplier information before sending it for approval.
        </QText>
      </QFlex>
      <QStack spacing={6}>
        <QBox>
          {status === 'touched' && !!touched && Object.keys(errors).length ? (
            <QAlert
              status="warning"
              title="You seem to have some required fields missing or some fields with invalid values"
              description={
                <QBox>
                  <QStack spacing={2}>
                    {Object.entries(errors).map(([name, error], index) => (
                      <WarningMessage
                        key={index}
                        name={name}
                        error={error as string}
                        handleStepChange={handleStepChange}
                      />
                    ))}
                  </QStack>
                </QBox>
              }
            />
          ) : null}
        </QBox>
        <QStack spacing={4}>
          <QHeading size="sm">General information</QHeading>
          <ApprovalSimpleItem title="Supplier name" description={values.name} />
          <ApprovalSimpleItem title="Supplier type" description={values.supplierType?.label} />
          <ApprovalSimpleItem title="Website" description={values.website} />
          <ApprovalSimpleItem title="Intended use" description={values?.intendedUse} />
          <ApprovalSimpleItem title="Sponsor" description={values.sponsor?.label} />
        </QStack>

        <QStack spacing={4}>
          <QHeading size="sm">Supplier contact information</QHeading>
          <ApprovalSimpleItem title="Name" description={values.contactName} />
          <ApprovalSimpleItem title="Email" description={values.contactEmail} />
          <ApprovalSimpleItem title="Phone" description={values.contactPhone} />
          <ApprovalSimpleItem title="Address" description={values.contactAddress} />
          <ApprovalSimpleItem title="Notes" description={values.contactNotes} />
        </QStack>

        <QDivider />
        <QBox maxWidth="800px">
          <QHeading size="sm" pb={4}>
            Risk level
          </QHeading>
          {values?.riskType.title ? (
            <>
              <QTag>{values.riskType?.title}</QTag>
              <QText fontSize="sm" color="gray.500" pt={2}>
                {isEmpty(values.riskType?.description)}
              </QText>
            </>
          ) : (
            <QText fontSize="sm" color="gray.500">
              --
            </QText>
          )}
        </QBox>
        <QDivider />
        <QStack spacing={4}>
          <QHeading size="sm">Supporting documents</QHeading>
          <QBox display="flex" flexDirection="column" maxWidth="800px">
            {formDocuments
              .filter((doc) => doc.name !== ADDITIONAL_DOCUMENTS)
              .map((document: Document, index: number) => (
                <QStack key={index} direction="column" spacing={4}>
                  <QHeading size="xs">{document?.name}</QHeading>
                  {document.files?.length ? (
                    document.files?.map((file: File, index: number) => (
                      <QBox pb={4} key={index}>
                        <ViewFileCard key={index} file={file} />
                      </QBox>
                    ))
                  ) : (
                    <QText fontSize="sm" color="gray.500" pb={4}>
                      There are no mandatory documents
                    </QText>
                  )}
                </QStack>
              ))}
          </QBox>
        </QStack>
        <QStack spacing={4}>
          <QHeading size="sm">Additional documents</QHeading>
          <QBox display="flex" flexDirection="column" maxWidth="800px">
            {formDocuments.find((doc) => doc.name === ADDITIONAL_DOCUMENTS)?.files?.length ? (
              formDocuments
                .filter((doc) => doc.name === ADDITIONAL_DOCUMENTS)
                .map((document: Document, index: number) => (
                  <QStack key={index} direction="column" spacing={4}>
                    {document.files?.map((file: File, index: number) => (
                      <QBox key={index}>
                        <ViewFileCard key={index} file={file} />
                      </QBox>
                    ))}
                  </QStack>
                ))
            ) : (
              <QText fontSize="sm" color="gray.500">
                There are no additional documents
              </QText>
            )}
          </QBox>
        </QStack>
        <QDivider />
        <QStack spacing={2}>
          <QHeading size="sm">Required audits</QHeading>
          {requiredAudits?.length ? (
            <QText fontSize="sm" color="gray.500">
              For the supplier type <b>{values?.supplierType?.label} </b> and the risk level{' '}
              <b>{values?.riskType?.title}</b> your{' '}
              <QLink isExternal href={`/sqm/${routes.policy.root}/${routes.policy.audits}`}>
                supplier policy
              </QLink>{' '}
              requires these audits
            </QText>
          ) : (
            <QText fontSize="sm" color="gray.500">
              For the supplier type <b> {values?.supplierType?.label} </b> and the risk level{' '}
              <b> {values?.riskType?.title}</b> your{' '}
              <QLink isExternal href={`/sqm/${routes.policy.root}/${routes.policy.audits}`}>
                supplier policy
              </QLink>{' '}
              does not require any mandatory audits
            </QText>
          )}
          {requiredAudits?.length
            ? requiredAudits.map((audit) => (
                <QBox border="1px" borderColor="blackAlpha.200" borderRadius="base" px={3} py={3} key={audit.name}>
                  <QText color="gray.800" fontSize="sm" pb={3}>
                    {audit.name}
                  </QText>
                  <QText color="gray.500" fontSize="xs">
                    {audit.frequency
                      ? 'Every ' + maybePluralize(audit.frequency as unknown as number, 'month')
                      : 'Only Once'}
                  </QText>
                </QBox>
              ))
            : ''}
        </QStack>

        <QAlert
          status="warning"
          description="If a review of audits is needed before approval, you can save this supplier and add the audit and all its documents in the “Audits” section of this supplier.
"
        />
        <QDivider />

        <QStack spacing={4}>
          <QHeading size="sm">Approval</QHeading>
          <QFlex align="center" mb={6}>
            <QIcon iconName="Info" color="gray.500" />
            <QText fontSize="sm" color="gray.500" pl={2}>
              Select people who can approve this supplier. At least one quality user should be selected. Approval will
              be granted when all of them accept.
            </QText>
          </QFlex>
          <QFormControl
            label="Approvers"
            isInvalid={isFieldInvalid('approvers')}
            error={errors.approvers as string}
            helper="This field is required when sending for approval."
          >
            <QMultiSelect
              name="approvers"
              onChange={(e) => setFieldValue('approvers', e)}
              value={values.approvers.map((user) => user.value)}
              options={usersOptions}
              isLoading={isCompanyUsersLoading}
            />
          </QFormControl>
        </QStack>
      </QStack>
    </QBox>
  );
};
