import React, { useState } from 'react';

import { QAlert, QDivider, QButton, QEmptyState, QStack, QText, useCurrentUser } from '@qualio/ui-components';
import supplierApi from 'api/supplier.api';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils';
import { SupplierList, ImportModal } from 'v2components';

const SupplierListView: React.FC = () => {
  const { companyId } = useCurrentUser();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showOnlyArchived, setShowOnlyArchived] = useState<boolean>(
    () => localStorage.getItem('sqm-fe-list-suppliers-archive-toggle') === 'true',
  );

  const setShowOnlyArchivedCallback = (showOnlyArchived: boolean) => {
    localStorage.setItem('sqm-fe-list-suppliers-archive-toggle', showOnlyArchived.toString());
    setShowOnlyArchived(showOnlyArchived);
  };

  const {
    isLoading: isSuppliersDataLoading,
    error,
    refetch: refetchSuppliers,
    data: suppliersData,
  } = useQuery('suppliersList', () => supplierApi.getAllByCompany(companyId), {
    enabled: !showOnlyArchived,
  });

  const {
    isLoading: isArchivedDataLoading,
    error: errorsOnArchivalCall,
    data: suppliersArchivedData,
  } = useQuery('archivedSuppliersList', () => supplierApi.getAllSuppliersByCompanyAndStatus(companyId, 'ARCHIVED'), {
    enabled: showOnlyArchived,
  });

  const data = showOnlyArchived ? suppliersArchivedData : suppliersData;

  return (
    <>
      {(error || errorsOnArchivalCall) && <QAlert description="Something went wrong" status="warning" />}
      {!data?.length && !isSuppliersDataLoading && !isArchivedDataLoading && !showOnlyArchived ? (
        <>
          <div data-cy={'suppliers-empty-state'}>
            <QEmptyState
              title="You don’t have any suppliers yet"
              subtitle="Before you add a supplier it’s recommended to create a supplier policy. The policy will define supplier types you work with, risk levels that will help assess your suppliers and required types of documents each supplier needs to have to be approved."
              primaryButtonProps={{
                label: 'Add supplier',
                onClick: () => navigate(`/${routes.addSupplier}`),
              }}
            />
          </div>

          <QStack direction="row" alignItems="center" pb={4}>
            <QDivider orientation="horizontal" />
            <QText>or</QText>
            <QDivider orientation="horizontal" />
          </QStack>
          <QStack direction="column" alignItems="center" spacing={4}>
            <QButton aria-label="icon-button" size="sm" variant="outline" onClick={() => setIsModalOpen(true)}>
              Import Suppliers
            </QButton>
            <QButton
              aria-label="icon-button"
              size="sm"
              variant="outline"
              onClick={() =>
                navigate(`/${routes.policyConfiguration.root}/${routes.policyConfiguration.supplierTypes}`)
              }
            >
              Configure Policy
            </QButton>
            <ImportModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} refetchSuppliers={refetchSuppliers} />
          </QStack>
        </>
      ) : (
        <SupplierList
          suppliers={data}
          refetchSuppliers={refetchSuppliers}
          showOnlyArchived={showOnlyArchived}
          setShowOnlyArchived={setShowOnlyArchivedCallback}
          isLoading={isSuppliersDataLoading || isArchivedDataLoading}
        />
      )}
    </>
  );
};

export default SupplierListView;
