import React, { useEffect, useState } from 'react';

import {
  QButton,
  QFormControl,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  useToastProvider,
  QCloseButton,
  QAlert,
  useCurrentUser,
} from '@qualio/ui-components';
import supplierApi from 'api/supplier.api';
import { useMutation, useQuery } from 'react-query';

type ImportModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchSuppliers: any;
};

const ImportModal: React.FC<ImportModalProps> = ({ isOpen, setIsOpen, refetchSuppliers }) => {
  const [templatePrefix, setTemplatePrefix] = useState<string>();
  const [templateValue, setTemplateValue] = useState<string>();

  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const {
    data: docs,
    isLoading: isSearching,
    refetch: refetchDocs,
    isRefetching,
  } = useQuery('qualioDocs', () => supplierApi.getQualioDocsByPrefix(companyId, templateValue as string), {
    retry: false,
    enabled: false,
  });
  const { mutateAsync: importSuppliersMutation, isLoading: isImporting } = useMutation(
    (documentIds: number[]) => {
      return supplierApi.importSuppliers(companyId, documentIds);
    },
    {
      onSuccess: () => {
        showToast({
          title: 'Import Successful',
          description: 'Suppliers have been imported successfully',
          status: 'success',
        });
      },
      onError: () => {
        showToast({
          title: 'Import Failed',
          description: 'Failed to import suppliers',
          status: 'error',
        });
      },
    },
  );

  const handleCloseModal = () => {
    setTemplateValue('');
    setTemplatePrefix('');
    setIsOpen(false);
  };

  useEffect(() => {
    if (templatePrefix) refetchDocs();
  }, [templatePrefix, refetchDocs]);

  const updateTemplatePrefix = () => {
    setTemplatePrefix(templateValue);
    refetchDocs();
  };

  const importSuppliers = async (docs: number[]) => {
    await importSuppliersMutation(docs);
    setIsOpen(false);
    setTemplateValue('');
    setTemplatePrefix('');
    await refetchSuppliers();
  };

  return (
    <QModal isOpen={isOpen} onClose={() => setIsOpen(false)} size="xl">
      <QModalHeader>
        <QText>Import Suppliers</QText>
        <QCloseButton onClick={() => handleCloseModal()} />
      </QModalHeader>
      <QModalBody>
        <QStack direction="column">
          <QText fontSize={'sm'} mb={2}>
            If you store suppliers records as Qualio Docs, you can import them into the Suppliers list. Enter the
            template prefix of the supplier records
          </QText>
          <QFormControl label="Template prefix" size="sm" helper={`For example "SQF"`} pb={4}>
            <QInput
              //TO-DO Fix controlled input console error
              width="80%"
              value={templateValue}
              onChange={(e: any) => setTemplateValue(e.target.value)}
              mr={8}
            />
            <QButton
              variant="ghost"
              size="sm"
              isLoading={isSearching || isRefetching}
              onClick={() => updateTemplatePrefix()}
            >
              Search
            </QButton>
          </QFormControl>

          {!!templatePrefix &&
            !isSearching &&
            !isRefetching &&
            (docs?.length ? (
              <QAlert
                description={`${docs?.length} supplier records were found with the prefix ${templatePrefix}`}
                status={'success'}
              ></QAlert>
            ) : (
              <QAlert
                description={`No supplier records were found with the prefix ${templatePrefix}`}
                status={'error'}
              ></QAlert>
            ))}
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={() => handleCloseModal()} variant="outline">
          Cancel
        </QButton>
        <QButton
          variant="solid"
          isLoading={isImporting}
          isDisabled={!docs?.length}
          onClick={() => importSuppliers(docs || [])}
        >
          Import
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default ImportModal;
