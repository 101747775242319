import React, { useEffect } from 'react';

import { QAlert, QBox, QSpinner, useCurrentUser } from '@qualio/ui-components';
import { useCurrentUserQuery } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Settings } from 'luxon';
import { BrowserRouter as Router } from 'react-router-dom';
import InsufficientPermissionRoutes from 'routes/insufficientPermissionRoutes';
import { isLocal, registerLocalLocale } from 'utils';

import DefaultRoutes from './routes/defaultRoutes';
import EmergencyBreakRoutes from './routes/emergencyBreakRoutes';
import UpSellRoutes from './routes/upSellRoutes';
import UpSellStarterRoutes from './routes/upSellStarterRoutes';

const App: React.FC = () => {
  const { sqmGaUpsellCustomer, sqmEmergencyBreak } = useFlags();
  const { tz } = useCurrentUser();
  const { permissions, userGroups, userError, isUserLoading, isValidSuppliersPlan } = useCurrentUserQuery();

  const isAccessNotAllowedToSuppliers = () => {
    return permissions.length && !permissions.includes('feature_enabled_supplier_quality_management');
  };

  useEffect(() => {
    registerLocalLocale();
    Settings.defaultZone = tz;
  }, [tz]);

  if (isUserLoading) {
    return (
      <QBox w="100%" textAlign="center" p={5}>
        <QSpinner />
      </QBox>
    );
  }

  if (userError && isLocal()) {
    return <QAlert status="warning" description="Please update your token to fetch user information" />;
  }

  let routes: JSX.Element;

  switch (true) {
    case !!sqmEmergencyBreak:
      routes = <EmergencyBreakRoutes />;
      break;
    case !!sqmGaUpsellCustomer:
      routes = <UpSellRoutes />;
      break;
    case !isValidSuppliersPlan:
      routes = <UpSellStarterRoutes />;
      break;
    case isAccessNotAllowedToSuppliers():
      routes = <InsufficientPermissionRoutes />;
      break;
    default:
      routes = <DefaultRoutes userGroups={userGroups} />;
      break;
  }

  return <Router basename={'/sqm/'}>{routes}</Router>;
};

export default App;
