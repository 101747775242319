import React, { useCallback } from 'react';

import { QEmptyState } from '@qualio/ui-components';
import { Outlet, useNavigate } from 'react-router-dom';

export type SupplierUpSellStarterViewProps = {
  contactEmail: string;
};

const SupplierUpSellStarterView: React.FC<SupplierUpSellStarterViewProps> = ({ contactEmail }) => {
  const navigate = useNavigate();
  const showProductVideo = useCallback(() => {
    navigate('/product-video');
  }, [navigate]);
  const openContact = useCallback(() => {
    window.open(`mailto:${contactEmail}`);
  }, [contactEmail]);
  return (
    <>
      <div data-cy={'upsell'}>
        <QEmptyState
          title="Trying to do some supply chain work?"
          subtitle={[
            'Unfortunately, you currently don’t have access to the Suppliers module.',
            'Contact our support team so you can easily onboard, manage',
            'and continuously improve your supplier base.',
          ]}
          secondaryButtonProps={{
            label: 'Watch product video',
            onClick: showProductVideo,
          }}
          primaryButtonProps={{
            label: 'Contact Qualio support',
            onClick: openContact,
          }}
        />
      </div>
      <Outlet />
    </>
  );
};

export default SupplierUpSellStarterView;
