import { prepareAuditPayload, prepareSupplierPayload } from 'lib';
import { Document, File, PayloadDocument, User } from 'providers/types';
import { Audit, Payload, ReviewResult, Supplier, SupplierStatus, SupplierExportResponse } from 'types';
import type { UpdateSupplierStatusValues } from 'v2components';
import { Config, UpsertSupplierApiPayload } from 'v2types';

import { api, fileApi } from './index';

const supplierApi = {
  async getAllByCompany(company?: number): Promise<Supplier[] | undefined> {
    if (company) {
      const res = await api.get(`/company/${company}/sqm/supplier`);
      return (res.data as Payload<Supplier[]>).data;
    }
  },
  async getAllSuppliersByCompanyAndStatus(company?: number, status?: SupplierStatus): Promise<Supplier[] | undefined> {
    if (company && status) {
      const res = await api.get(`/company/${company}/sqm/supplier/list/${status}`);
      return (res.data as Payload<Supplier[]>).data;
    }
  },
  async getSupplierById(company?: number, supplierId?: string): Promise<Supplier | undefined> {
    if (supplierId) {
      const res = await api.get(`/company/${company}/sqm/supplier/${supplierId}`);
      const supplier = (res.data as Payload<Supplier>).data;
      return {
        ...supplier,
        documents: supplier.documents?.map((document) => {
          return {
            ...document,
            files: document?.files?.filter((file: File) => {
              if (file?.fileName === undefined) {
                console.warn('filtering files without fileName from supplier api response');
              }
              return file?.fileName;
            }),
          };
        }),
      };
    }
  },
  async importSuppliers(company: number, documentIds: number[]): Promise<number> {
    const response = await api.post(`/company/${company}/sqm/import-suppliers`, { data: documentIds });
    return response.status;
  },
  async createSupplier(
    company: number | undefined,
    request: Supplier,
    redirect?: boolean,
  ): Promise<{ supplier: Supplier; redirect: boolean }> {
    const usersResponse = await this.listUsers(company);
    const modifiedPayload = prepareSupplierPayload(request, usersResponse);
    const response = await api.post(`/company/${company}/sqm/supplier`, { data: { ...modifiedPayload } });
    return { supplier: (response.data as Payload<Supplier>).data, redirect: redirect === undefined ? true : redirect };
  },
  async createSupplierV2(
    company: number | undefined,
    payload: UpsertSupplierApiPayload,
    redirect?: boolean,
  ): Promise<{ supplier: Supplier; redirect: boolean }> {
    const response = await api.post(`/company/${company}/sqm/supplier`, { data: { ...payload } });
    return { supplier: (response.data as Payload<Supplier>).data, redirect: redirect === undefined ? true : redirect };
  },
  async sendForReview(company: number | undefined, supplier: string): Promise<any> {
    const { data } = await api.post<{ data: Supplier }>(`/company/${company}/sqm/supplier/${supplier}/sendForReview`);
    return data.data;
  },
  async updateSupplierStatus(
    company: number,
    supplierId: string | undefined,
    request: UpdateSupplierStatusValues,
  ): Promise<ReviewResult> {
    const response = await api.post(`/company/${company}/sqm/supplier/${supplierId}/review`, { data: { ...request } });
    return (response.data as Payload<ReviewResult>).data;
  },
  async archive(company: number, supplier: string): Promise<Supplier> {
    const res = await api.delete(`/company/${company}/sqm/supplier/${supplier}`);
    return (res.data as Payload<Supplier>)?.data;
  },
  async export(company: number, supplier: string): Promise<SupplierExportResponse> {
    const res = await api.post(`/company/${company}/sqm/supplier/${supplier}/export`);
    return (res.data as Payload<SupplierExportResponse>)?.data;
  },
  async exportSpreadsheet(company: number, suppliers: string[]): Promise<SupplierExportResponse> {
    const res = await api.post(`/company/${company}/sqm/supplier/export`, { supplierIds: suppliers });
    return (res.data as Payload<SupplierExportResponse>)?.data;
  },
  async revertToDraft(company: number, supplier: string): Promise<Supplier> {
    const res = await api.post(`/company/${company}/sqm/supplier/${supplier}/revert`);
    return (res.data as Payload<Supplier>)?.data;
  },
  async listConfigs(company: number | undefined): Promise<Config[]> {
    const payload = await api.get(`/company/${company}/sqm/config`);
    return payload.data?.data;
  },
  async postConfig(company: number, config: Config): Promise<Config> {
    const payload = await api.put(`/company/${company}/sqm/config`, { data: { ...config } });
    return payload.data?.data;
  },
  async listUsers(company: number | undefined): Promise<User[]> {
    const payload = await api.get(`/company/${company}/sqm/users`);
    return payload.data?.data;
  },
  async getUploadUrl(company: number, config: PayloadDocument): Promise<Document> {
    const payload = await api.get(`/company/${company}/sqm/upload`, { data: { ...config } });
    return payload.data?.data;
  },
  async updateDocumentStatus(company: number, id: string, config: PayloadDocument): Promise<Document> {
    const payload = await api.put(`/company/${company}/sqm/document/${id}`, { data: { ...config } });
    return payload.data?.data;
  },
  async uploadDocument(url: string, document: Document): Promise<Document> {
    const payload = await fileApi.put(url, document, { withCredentials: false });
    return payload.data?.data;
  },
  async deleteDocument(company: number, documentId: string | undefined): Promise<string> {
    const payload = await api.delete(`/company/${company}/sqm/document/${documentId}`);
    return payload.data?.data;
  },
  async fetchDocumentGetURL(company: number, documentId: string): Promise<any> {
    const payload = await api.get(`/company/${company}/sqm/document/${documentId}`);
    return payload.data;
  },
  async getPolicyLinks(company: number): Promise<any> {
    const payload = await api.get(`/company/${company}/sqm/config/policy/links`);
    return payload.data?.data;
  },

  async addPolicyLink(company: number, linkData: { supplierType: any; riskLevel: any; linkData: any }): Promise<any> {
    const payload = await api.post(`/company/${company}/sqm/config/policy/link`, { data: linkData });
    return payload.data?.data;
  },
  async deletePolicyLink(
    company: number,
    linkData: { supplierType: any; riskLevel: any; documentType: any },
  ): Promise<any> {
    const { supplierType, riskLevel, documentType } = linkData;
    const payload = await api.delete(
      `/company/${company}/sqm/config/policy/link/risk/${riskLevel.id}/type/${supplierType.id}/document/${
        documentType.id || documentType.value
      }`,
    );
    return payload.data?.data;
  },
  async createAudit(company: number, supplier: string, audit: Audit): Promise<Audit> {
    const payload = await api.post(`/company/${company}/sqm/supplier/${supplier}/audit`, { data: { ...audit } });
    return payload.data?.data;
  },

  async updateAudit(company: number, supplier: string, audit: Audit): Promise<Audit> {
    const modifiedRequestPayload = prepareAuditPayload(audit);
    const payload = await api.put(`/company/${company}/sqm/supplier/${supplier}/audit/${audit.audit}`, {
      data: { ...modifiedRequestPayload },
    });
    return payload.data?.data;
  },

  async getAuditById(company: number, supplier: string, audit: Audit): Promise<Audit> {
    const payload = await api.get(`/company/${company}/sqm/supplier/${supplier}/audit/${audit}`);
    return payload.data?.data;
  },

  async getAuditsBySupplierId(company: number, supplier: string): Promise<Audit[]> {
    const payload = await api.get(`/company/${company}/sqm/supplier/${supplier}/audit`);
    return payload.data?.data;
  },

  async getQualioDocsByPrefix(company: number, templatePrefix: string): Promise<number[]> {
    let documentIds: number[] = [];
    if (company) {
      const res = await api.get(`/company/${company}/sqm/qualio-documents/${templatePrefix}`);
      documentIds = res.data?.data || [];
    }
    return documentIds;
  },
};

export default supplierApi;
