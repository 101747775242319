import React, { useCallback } from 'react';

import { QEmptyState } from '@qualio/ui-components';
import { Outlet, useNavigate } from 'react-router-dom';

const SuppliersInsufficientPermissionsView: React.FC = () => {
  const navigate = useNavigate();
  const showProductVideo = useCallback(() => {
    navigate('/product-video');
  }, [navigate]);
  return (
    <>
      <div data-cy={'suppliers-insufficient-permissions'}>
        <QEmptyState
          title="Trying to do some supply chain work?"
          subtitle={[
            'Unfortunately, you currently don’t have access to the Suppliers module.',
            'Contact your admin so you can easily onboard, manage',
            'and continuously improve your supplier base.',
          ]}
          secondaryButtonProps={{
            label: 'Watch product video',
            onClick: showProductVideo,
          }}
        />
        <Outlet />
      </div>
    </>
  );
};

export default SuppliersInsufficientPermissionsView;
