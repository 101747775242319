import React, { useEffect, useState, Dispatch, useMemo } from 'react';

import {
  QBox,
  QButton,
  QButtonGroup,
  QHeading,
  QInput,
  QMenuButton,
  QSelect,
  QStack,
  QDataTable,
  QTag,
  QSelectItem,
  useToastProvider,
  QSpinner,
  QSwitch,
  useAnalytics,
  QSelectPlaceholder,
  QText,
  QDataColumn,
  useCurrentUser,
} from '@qualio/ui-components';
import { pdf } from '@react-pdf/renderer';
import supplierApi from 'api/supplier.api';
import { saveAs } from 'file-saver';
import { useConfigsQuery, useSupplierSpreadsheetExportMutation } from 'hooks';
import { downloadCSV, STATUS_CELL_MAPPING, SUPPLIER_STATUS } from 'lib';
import { useQuery } from 'react-query';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Supplier, SupplierSettingOption } from 'types';
import { getNextAuditDue, routes } from 'utils';
import { ASLExportDocument } from 'v2components';
import { ConfigItem } from 'v2types';

import ImportModal from './components/ImportModal';
import LastAudit from './components/LastAudit';
import NextAuditDue from './components/NextAuditDue';

type SupplierListProps = {
  suppliers: Supplier[] | undefined;
  refetchSuppliers: () => void;
  showOnlyArchived: boolean;
  setShowOnlyArchived: Dispatch<boolean>;
  isLoading: boolean;
};

const sortDate = (prevDate: Date, nextDate: Date) => {
  return prevDate > nextDate ? 1 : -1;
};

const COLUMNS: QDataColumn = [
  { Header: 'Name', accessor: 'name', width: '30%' },
  { Header: 'Type', accessor: 'type', minWidth: '115px' },
  {
    Header: 'Status',
    accessor: 'status',
    minWidth: '105px',
    sortType: (prev: any, next: any) => {
      const a = prev?.original?.status?.props?.children || '';
      const b = next?.original?.status?.props?.children || '';
      if (a < b) return -1;
      if (b < a) return 1;
      return 0;
    },
  },
  { Header: 'Risk', accessor: 'risk', minWidth: '90px' },
  { Header: 'Sponsor', accessor: 'sponsor', minWidth: '176px' },
  {
    Header: 'Last modified',
    accessor: 'lastModified',
    sortType: (prev: any, next: any) => sortDate(prev.values.lastModified, next.values.lastModified),
    type: 'DATE',
    width: '120px',
  },
  {
    Header: 'Last audit',
    accessor: 'lastAudit',
    disableSortBy: true,
    width: '105px',
  },
  {
    Header: 'Due in',
    accessor: 'dueIn',
    disableSortBy: true,
    width: '120px',
  },
];

const getTagVariantColor = (key: string) => {
  switch (key) {
    case 'APPROVED':
      return 'green';
    case 'REVIEW_PENDING':
      return 'blue';
    case 'REJECTED':
      return 'red';
    case 'ARCHIVED':
      return 'yellow';
    default:
      return 'gray';
  }
};

const SUPPLIER_STATUS_OPTIONS = Object.keys(SUPPLIER_STATUS)
  .filter((key) => key !== 'ARCHIVED')
  .map((key) => ({
    label: SUPPLIER_STATUS[key].text,
    value: key,
  }));

type FiltersType = {
  name: string;
  riskType: string;
  status: string;
  supplierType: string;
};

const DEFAULT_FILTERS = { name: '', riskType: '', status: '', supplierType: '' };

const SupplierList: React.FC<SupplierListProps> = ({
  suppliers,
  refetchSuppliers,
  showOnlyArchived,
  setShowOnlyArchived,
  isLoading,
}) => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { isConfigsLoading, configs } = useConfigsQuery();

  const { companyId } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<FiltersType>(() =>
    !!searchParams.get('filters') ? JSON.parse(searchParams.get('filters') as string) : DEFAULT_FILTERS,
  );
  const [supplierTypeOptions, setSupplierTypeOptions] = useState<SupplierSettingOption[] | []>([]);
  const [supplierRiskLevelOptions, setSupplierRiskLevelOptions] = useState<SupplierSettingOption[] | []>([]);
  const [auditTypes, setAuditTypes] = useState<ConfigItem[]>();
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const approvedSuppliers = suppliers?.filter((supplier) => supplier.status === 'APPROVED');
  const { showToast } = useToastProvider();
  const { isExportSupplierSpreadsheetLoading, exportSupplierSpreadsheetMutate } =
    useSupplierSpreadsheetExportMutation();
  const analytics = useAnalytics();

  const { data: policyLinks } = useQuery('supplierPolicyLinks', () => supplierApi.getPolicyLinks(companyId), {
    enabled: isConfigsLoading,
    onError: () => {
      showToast({
        title: 'Error',
        description: 'Cannot retrieve policy links',
        status: 'error',
      });
    },
  });

  const mapStringToFilterKey = (key: string) => key as keyof FiltersType;
  const isFilterActive = (key: keyof FiltersType) => filters[key];

  const hasValidFilters = Object.keys(filters).map(mapStringToFilterKey).filter(isFilterActive).length;

  const filteredItems = hasValidFilters
    ? (suppliers?.filter((supplier: any) => {
        let includeSupplier = true;
        Object.keys(filters)
          .map(mapStringToFilterKey)
          .filter(isFilterActive)
          .forEach((key: keyof FiltersType) => {
            if (key === 'supplierType' || key === 'riskType') {
              return (includeSupplier = includeSupplier && supplier?.[key]?.['id'].includes(filters[key]));
            }
            return (includeSupplier =
              includeSupplier && supplier[key]?.toLowerCase().includes(filters[key].toLowerCase().trim()));
          });

        return includeSupplier;
      }) as Supplier[])
    : suppliers;

  const mappedFilteredItems = filteredItems?.map((supplier: Supplier) => ({
    id: supplier.supplier || '',
    name: supplier.name || '',
    type: supplier.supplierType?.title || '',
    status: supplier?.status ? (
      <QTag variantColor={getTagVariantColor(supplier?.status || '')}>
        {STATUS_CELL_MAPPING[supplier?.status].text || ''}
      </QTag>
    ) : (
      ''
    ),
    risk: supplier?.riskType?.title || '',
    sponsor: supplier?.sponsor?.fullName || supplier?.sponsor?.full_name || '',
    lastModified: new Date(supplier.modified!),
    lastAudit: <LastAudit audits={supplier.audits?.filter((audit) => audit.status !== 'ARCHIVED')} />,
    dueIn:
      supplier.status === 'ARCHIVED' ? (
        '--'
      ) : (
        <NextAuditDue
          audits={supplier.audits}
          policyLinks={policyLinks}
          supplierType={supplier?.supplierType as ConfigItem}
          riskLevel={supplier?.riskType as ConfigItem}
          auditTypes={auditTypes}
          supplier={supplier.supplier}
        />
      ),
  }));

  const updateFilters = (type: string, value: string) => {
    const updatedFilters = { ...filters, [type]: value };
    setSearchParams(createSearchParams({ filters: JSON.stringify(updatedFilters) }));
    setFilters(updatedFilters);
  };

  const MENU_ITEMS = [
    {
      id: 'import',
      label: 'Import',
      handler: () => {
        setIsImportModalOpen(true);
      },
    },
    {
      id: 'exportAsl',
      label: 'Export ASL',
      handler: async () => {
        if (!approvedSuppliers?.length) {
          showToast({
            title: 'Error',
            description: 'No approved suppliers to export',
            status: 'error',
          });
          return;
        }
        const document = await pdf(
          <ASLExportDocument
            suppliers={approvedSuppliers as Supplier[]}
            currentUser={user}
            policyLinks={policyLinks}
            auditTypes={auditTypes}
          />,
        ).toBlob();
        saveAs(document, 'Approved_Supplier_List.pdf');
      },
    },
  ];

  const GRID_MENU_ITEMS = useMemo(
    () => [
      {
        'data-cy': 'csv-export-button',
        id: 'exportCurrentView',
        label: 'Export current view to CSV',
        handler: () => {
          analytics.track('Suppliers | Export Supplier List as CSV');
          downloadCSV(
            filteredItems?.map((supplier) => ({
              ...supplier,
              ...getNextAuditDue(
                supplier.audits,
                supplier?.supplierType as ConfigItem,
                policyLinks,
                supplier?.riskType as ConfigItem,
                auditTypes,
                supplier.supplier,
              ),
            })) ?? [],
          );
        },
      },
      {
        'data-cy': 'xlsx-export-button',
        id: 'exportSpreadsheet',
        label: 'Export full view to XLSX',
        handler: () =>
          filteredItems &&
          filteredItems.length > 0 &&
          exportSupplierSpreadsheetMutate(
            filteredItems.filter((item) => !!item.supplier).map((item) => item.supplier!),
          ),
      },
    ],
    [filteredItems, exportSupplierSpreadsheetMutate, policyLinks, auditTypes, analytics],
  );

  useEffect(() => {
    if (isConfigsLoading && isExportSupplierSpreadsheetLoading) {
      return;
    }

    const supplierTypes = configs?.find((item) => item.type === 'Type')?.options || [];
    const riskLevels = configs?.find((item) => item.type === 'Risk')?.options || [];

    setAuditTypes(configs?.find((item) => item.type === 'Audit')?.options);
    setSupplierTypeOptions(
      supplierTypes?.map((supplierType) => ({ label: supplierType.title, value: supplierType.id })),
    );
    setSupplierRiskLevelOptions(riskLevels?.map((riskLevel) => ({ label: riskLevel.title, value: riskLevel.id })));
  }, [configs, isConfigsLoading, isExportSupplierSpreadsheetLoading]);

  return (
    <>
      <QBox my={4}>
        <QBox display="flex" flexDirection="row" justifyContent="space-between">
          <QHeading size="lg" as="h1">
            Suppliers
          </QHeading>
          <QButtonGroup data-cy="import-export-add-supplier-button-group">
            <QMenuButton
              items={MENU_ITEMS}
              buttonLabel="options"
              variant="icon"
              onItemClick={(clickedItem) => MENU_ITEMS.find((item) => clickedItem.id === item.id)?.handler()}
            />
            <QButton variant="solid" onClick={() => navigate(routes.addSupplier)} data-cy="add-supplier-button">
              Add supplier
            </QButton>
          </QButtonGroup>
        </QBox>
        <QBox pt={1} mt={5}>
          <QBox w={['100%', '100%', 'calc(25% - 12px)']} display="flex" flexDirection="column" mb={4}>
            <QInput
              data-cy="search-suppliers"
              iconLeftName="Search"
              placeholder="Search name"
              value={filters.name}
              onChange={(e: any) => updateFilters('name', e.target.value)}
            />
          </QBox>
          <QStack spacing={4} direction={['column', 'column', 'row']} alignItems="flex-start">
            <QBox w="100%">
              <QSelect
                aria-label="type-select"
                value={filters.supplierType}
                isClearable={true}
                options={supplierTypeOptions as QSelectItem[]}
                onChange={(e: any) => updateFilters('supplierType', e?.value || undefined)}
              >
                <QSelectPlaceholder>
                  <QText>Select supplier type</QText>
                </QSelectPlaceholder>
              </QSelect>
            </QBox>
            <QBox w="100%">
              <QSelect
                aria-label="status-select"
                value={filters.status}
                isClearable={true}
                options={SUPPLIER_STATUS_OPTIONS}
                onChange={(e: any) => updateFilters('status', e?.value || undefined)}
              >
                <QSelectPlaceholder>
                  <QText>Select status</QText>
                </QSelectPlaceholder>
              </QSelect>
            </QBox>
            <QBox w="100%">
              <QSelect
                aria-label="risk-select"
                value={filters.riskType}
                isClearable={true}
                options={supplierRiskLevelOptions as QSelectItem[]}
                onChange={(e: any) => updateFilters('riskType', e?.value || undefined)}
              >
                <QSelectPlaceholder>
                  <QText>Select risk type</QText>
                </QSelectPlaceholder>
              </QSelect>
            </QBox>
            <QBox w="100%" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
              <QSwitch
                id="archived-only"
                isChecked={showOnlyArchived}
                onChange={() => setShowOnlyArchived(!showOnlyArchived)}
                mr={2}
                mt={0.5}
                leftLabel="Show only archived"
              />
              <div data-cy="grid-menu">
                <QMenuButton
                  items={GRID_MENU_ITEMS}
                  buttonLabel="options"
                  variant="icon"
                  onItemClick={(clickedItem) => GRID_MENU_ITEMS.find((item) => clickedItem.id === item.id)?.handler()}
                />
              </div>
            </QBox>
          </QStack>
        </QBox>
        <QBox mt={5}>
          {isLoading ? (
            <QBox w="100%" textAlign="center" p={5}>
              <QSpinner />
            </QBox>
          ) : (
            <QDataTable
              data={mappedFilteredItems || []}
              columns={COLUMNS}
              manualSortBy={{
                defaultSortByColumn: [
                  {
                    id: 'lastModified',
                    desc: true,
                  },
                ],
              }}
              onRowClick={(supplier) =>
                navigate(`/${routes.supplierDetails.root}/${supplier?.id}/${routes.supplierDetails.detailsTab}`)
              }
            />
          )}
        </QBox>
      </QBox>
      <ImportModal isOpen={isImportModalOpen} setIsOpen={setIsImportModalOpen} refetchSuppliers={refetchSuppliers} />
      <div id="react-pfd" />
    </>
  );
};

export default SupplierList;
